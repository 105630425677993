import React from "react"
import 'uikit/dist/css/uikit.css'
import Layout from "../layouts/index"
import IceInfoParagraph from "../components/ice-info-paragraph/ice-info-paragraph.js";
import { privacy } from "../utils/information.js";


const Privacy = () => {


  return (
    <Layout
      SEOTitle={"Privacy and Cookie Policy - ICE"}
      SEODescription={"ICE Internet Comparison Experts Limited (“ICE”) Privacy Notice This privacy notice tells you what to expect when our organisation collects personal data, and how to contact us should you wish to discuss any aspects of how we handle that data. Who we are ICE is incorporated in Jersey (company number 107715) and is authorised by"}
      footerDisclaimer={""}>

      <IceInfoParagraph props={privacy} main={true}/>

    </Layout>
  )

}

export default Privacy